@media screen and (max-width: 580px) {
  #clinic-stuff-sidebar-information {
    display: none;
  }

  #patient-sidebar-general {
    display: none;
  }

  .sidebar-footer.sidebar-footer-content {
    display: none;
  }

  #sidebar .caret {
    display: none;
  }

  #sidebar .nav-text {
    display: none;
  }
}
