/*----------------------------------*/
/* GENERAL FORM STYLING */
/*----------------------------------*/
.content-wrapper {
  width: 100vw;
}

.survey-form {
  width: 100%;
  max-width: 1150px !important;
  min-width: none;
  padding-left: 1em !important;
  padding-right: 1em !important;
}

.survey-form .survey-description-text {
  color: #222;
  font-size: 1.1rem;
  margin-top: 2rem;
}

/* margin before control buttons*/
.survey-form form > div:last-of-type {
  margin-bottom: 2rem;
}
/* general margin */
.survey-form form {
  margin: 0.5rem 0;
}
/* FORM GROUP elements */
.survey-form form > div.survey-form-group {
  background-color: #f7f7f7;
  padding: 0.6rem 0rem;
  /*margin: 1.25rem 0;
  border-radius: 0.25rem;*/
}

/* INFO BOX */
.survey-form form > div.survey-info-element {
  margin: 1.25rem 0;
  padding: 1.25rem;
  border: 1px solid #e1e1e1;
  border-radius: 0.25rem;
}

.survey-form label {
  cursor: pointer;
}
.survey-form label em {
  font-size: 1rem;
  color: #848484;
}
.survey-form .form-label {
  /*margin-top: 1.5rem;*/
  font-size: 1.1rem;
  margin-left: 0.6rem;
}

.survey-form .form-check-inline {
  margin: 0 !important;
}

/*----------------------------------*/
/* RADIO BUTTONS */
/*----------------------------------*/

.survey-form .align-horizontal-scroll {
  /*
    width: 900px;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
  */

  /*
    width: 100%;
    display: flex;
    background: gray;
    flex-wrap: wrap;
    flex-direction: column;
    overflow-x: auto;
    white-space: nowrap;
  */
  /*
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  */

  /* width:100% Removed to allow element to expand
  display: inline-flex; */
  /*flex-direction: row;
  flex-wrap: nowrap;  Not needed since it the default behavior */

  overflow-x: scroll;
  display: flex;
  width: 100%;
}

.survey-form .without-horizontal-scroll {
  display: flex;
  width: 100%;
}

.survey-form .radio-col {
  /*display: inline-block;
  width: 178px;
  min-width: 200px;
  max-width: 178px;
  flex: 0 0 auto;*/

  /*width: 100px;
  max-width: 200px;
  flex-shrink: 0;
  margin-right: 10px;
  background-color: skyblue;*/
  width: 100%;
  max-width: 170px;
  display: inline-block;
  margin-right: 10px;
  /*background: #f0f0f0;*/
}

.survey-form .radio-vertical .radio-col {
  max-width: 100%;
  min-width: 100%;
}
.survey-form .radio-vertical .radio-custom {
  max-width: 100%;
  min-width: 100%;
}

.survey-form .radio-col label.radio-custom {
  width: 100%;
  max-width: 170px;
  height: 100%;
  cursor: pointer;
  margin: 0;
  padding: 0.6rem 1rem;
  font-size: 1.1rem;
  line-height: 1.5;
}

.survey-form .radio-col .form-check {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.survey-form .radio-col .form-check .form-check-input {
  cursor: pointer;
  margin-left: 0;
  /*margin-top: 0.25em;
  float: left;*/
  flex-shrink: 0;
}
.survey-form .radio-col .form-check-inline .form-check-input {
  cursor: pointer;
}

.survey-form .radio-col .form-check .form-check-label {
  max-width: 170px;
}

.survey-form .radio-vertical .radio-col .form-check .form-check-label {
  max-width: 100%;
}

/*----------------------------------*/
/* RADIO GRID TABLE */
/*----------------------------------*/
.survey-form .grid-table {
  /*padding: 0 !important;*/
  min-width: 0 !important;
}

.survey-form .radio-grid-table {
  padding: 1rem;
}

.survey-form .radio-grid-table-element .radio-grid-table-head {
  font-weight: 500;
  /*word-break: break-all;*/
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

.survey-form .radio-grid-table-element .radio-grid-table-head label {
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

.survey-form .radio-grid-table-element .radio-col {
  width: 135px !important;
  min-width: 135px !important;
}

.survey-form .radio-grid-table-element .radio-col label.radio-custom {
  width: 135px !important;
  min-width: 135px !important;
  text-align: center;
}
.survey-form
  .radio-grid-table-element
  .radio-col
  .form-check
  .form-check-label {
  width: 135px !important;
  min-width: 135px !important;
}

/*
.survey-form .radio-grid-table-element .radio-grid-table-head .radio-col {
  width: 50px;
}*/
.survey-form .radio-grid-table-element .radio-grid-table-head .radio-custom {
  cursor: auto;
}

.survey-form .radio-grid-table-element .radio-label {
  color: #1e4569 !important;
  min-width: 30%;
  max-width: 30%;
  visibility: hidden;
  display: none;
  font-size: 1.1rem;
}
.survey-form .radio-grid-table-element .align-horizontal-scroll .radio-label {
  display: block;
  visibility: visible;
}

.survey-form .radio-grid-table-element .without-horizontal-scroll .radio-label {
  display: block;
  visibility: visible;
}

/*.survey-form form > div div div.radio-grid-table-element {
  margin: 0;
  padding: 0.5em 0.8em;
}*/

/*----------------------------------*/
/* RADIO HORIZONTAL ENFORCED */
/*----------------------------------*/
/*.survey-form .radio-horizontal-enforced {
  min-width: 900px !important;
}*/

/*.survey-form form > div {
  margin: 1.5em 0;
  padding: 1.7em 1em;
  background: #f7f7f7;
}*/

/*div:has(> .survey-form-element) {
  background-color: red;
}*/

/*.survey-form label:hover {
  background-color: rgb(221, 221, 221);
}*/

/*----------------------------------*/
/* MEDIA CHANGES */
/*----------------------------------*/
@media (max-width: 993px) {
  /*.survey-form .radio-custom {
    display: block;
    margin: auto;
    padding: 10px;
  }*/

  /*----------------------------------*/
  /* RADIO GRID TABLE */
  /*----------------------------------*/
  .survey-form
    .radio-grid-table-element
    .radio-label
    :not(.without-horizontal-scroll) {
    min-width: 100%;
    max-width: 100%;
    visibility: visible;
    display: block;
  }
  .survey-form .radio-grid-table-element .align-horizontal-scroll .radio-label {
    display: none;
    visibility: hidden;
    width: 0 !important;
    min-width: 0;
    max-width: 0;
  }

  .survey-form
    .radio-grid-table-element
    .without-horizontal-scroll
    .radio-label {
    display: block;
    visibility: visible;
    width: 0 !important;
    min-width: 30%;
    max-width: 30%;
  }

  /*.survey-form .radio-grid-table-element .radio-col {
    width: 120px !important;
    min-width: 120px !important;
  }*/

  /*.survey-form .radio-grid-table .radio-custom {
    display: flex;
  }*/

  .survey-form form {
    margin: 1rem 0;
  }
}

@media (max-width: 563px) {
  .survey-form .radio-grid-table-element .radio-col {
    width: 110px !important;
    min-width: 110px !important;
  }
}

.survey-form td {
  font-weight: unset !important;
}

/* Make arrows on number fields invisible */
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Other major browsers */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.question-highlighted {
  background-color: rgb(254, 130, 114) !important;
}
