fliesstext {
  color: #1b223c !important;
}

body {
  padding-bottom: 80px; /* This ensures that the footer does not hide the content. Maybe the value of this has to be changed later*/
}

.fliesstext p,
.fliesstext ul li,
.fliesstext a {
  font-size: 0.98rem;
}

.fliesstext h2 {
  font-size: 1.3rem;
  font-weight: 600;
}

.fliesstext h3 {
  font-size: 1.3rem;
}

.fliesstext h4 {
  font-size: 1.1rem;
}
.fliesstext h2 + h3 {
  padding-top: 0rem;
}

.fliesstext h4 + p,
.fliesstext h4,
.fliesstext h3 + p,
.fliesstext h3,
h2 + p,
.fliesstext h2 {
  padding-top: 1rem;
}

a {
  color: #009592;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #00726c;
  text-decoration: underline;
}

/* hide search in data-table*/
#hoverable-data-table_filter,
.dataTables_filter {
  display: none !important;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: center;
  border-top: 1px solid #e5e9f2;
}

.btn-success {
  color: #ffffff;
  background-color: #bcd743;
  border-color: #bcd743;
}

.btn-success:hover {
  color: #ffffff;
  background-color: #a1b239;
  border-color: #a1b239;
}

.btn-success:focus,
.btn-success.focus {
  color: #ffffff;
  background-color: #a1b239;
  border-color: #a1b239;
  box-shadow: 0 0 0 0.2rem rgba(73, 212, 167, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #ffffff;
  background-color: #bcd743;
  border-color: #bcd743;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #ffffff;
  background-color: #20a278;
  border-color: #1e9770;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(73, 212, 167, 0.5);
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Durchsuchen";
}

.card-header .btn span {
  color: #8a909d;
}

.card-header .btn span.badge {
  color: #ffffff;
}

.card-header .mdi-information {
  color: #1b223c !important;
}
/*Login*/
button .mdi-information {
  color: #8a909d;
}

.login .card-header {
  background-color: #ffffff;
}

.clinic-select {
  width: 100% !important;
  margin-left: 0 !important;
}

.form-label {
  color: #1e4569 !important;
}

/* end login*/

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  /*padding: 1rem 1.25rem;*/
  margin-bottom: 0.5rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem;
}

.badge-circle {
  width: 25px;
  height: 25px;
}

.media .icon {
  flex: none;
}

.bg-secondary {
  background-color: #009592 !important;
}

.rounded-circle-outline {
  border: 1px solid #1c446a;
  border-radius: 50% !important;
}
.rounded-circle-outline i {
  color: #1c446a;
}
.bg-grey {
  background-color: none;
}

.user-role {
  border: 2px solid #ffffff;
  margin-right: 1rem;
}

/*
.text-primary {
  color: #122b3d !important;
}*/

/*===== ALERT =====*/
label {
  text-transform: none;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "\f443";
  top: 0px;
  font-size: 15px;
  color: #8a909d;
  position: relative;
  /*  transform: rotate(45deg);*/
  font-family: "Material Design Icons";
}

.sidenav-item-link .sub:before {
  content: "\f444";
  top: 0px;
  margin-right: 5px;
  font-size: 15px;
  color: #8a909d;
  position: relative;
  /*  transform: rotate(45deg);*/
  font-family: "Material Design Icons";
}

.sidenav-item-link .sub li {
  padding-left: 20px;
  text-indent: 20px;
}

/* #documentation {
  background-color: #1b223c !important;
  padding-left: 0px !important;
}

#documentation li {
  padding-left: 40px;
} */

/* #documentation > #section-title {
  padding-left: 30px !important;
}  */

/* #info-sub-menu {
  padding-left: 0px !important;
}*/

.media-message .status.accepted,
.media-message .status.pending,
.media-message .status.failed {
  border-color: #ffffff;
}

.media-message .status.accepted {
  background-color: #bcd743;
}

.media-message .status.pending {
  background-color: #fec400;
}

.media-message .status.failed {
  background-color: #fe5461;
}
.media-message .title {
  color: #1b223c;
  font-size: 0.9375rem;
  margin-bottom: 0;
}

.media-message .last-msg {
  width: 180px;
  overflow: visible;

  margin-bottom: 0;
  color: #8a909d;
  font-size: 0.8rem;
}

.media-message.media-active {
  background-color: rgba(233, 236, 239, 0.5);
}

.media-message.media-active .title,
.media-message.media-active .last-msg,
.media-message.media-active .date {
  color: #1b223c;
}

.navbar .navbar-right .navbar-nav .notifications-menu .dropdown-toggle {
  position: relative;
  border-left: 1px solid #e5e9f2;
  border-right: 1px solid #e5e9f2;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  font-size: 1.625rem;
  color: rgba(138, 144, 157, 0.7);
  display: inline-block;
  line-height: 74px;
}

.navbar .navbar-right .navbar-nav .notifications-menu .dropdown-toggle:after {
  content: "";
}
.navbar .navbar-right .navbar-nav .notifications-menu .dropdown-toggle:before {
  background-color: #1c446a;
  color: #ffffff;
  content: "1";
  font-family: inherit;
  line-height: 1.2;
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  top: 15px;
  left: 35px;
}

.navbar {
  height: 50px;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

/* App brand */
.app-brand {
  position: relative;
  display: block;
  background-color: #ffffff;
  /* border-right: 1px solid #e5e9f2; */
}

.app-brand a {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
  line-height: 75px;
  width: 350px !important;
  transition: width 0.3s ease-in-out;
  padding-left: 1.56rem;
  padding: 10px;
}

@media (prefers-reduced-motion: reduce) {
  .app-brand a {
    transition: none;
  }
}

@media (min-width: 768px) {
  .app-brand a {
    overflow: hidden;
  }
}

.app-brand a img {
  /* max-width: 180px; */
  vertical-align: middle;
  width: 300px !important;
}

.app-brand .brand-name {
  color: #1b466c;
  font-size: 1.5rem;
  margin-left: 0rem;
  line-height: auto;
  max-width: 170px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
}
.app-brand .brand-name .green {
  color: #bcd743;
}

/* Tooltip Inner */
.tooltip-inner {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  padding: 0.56rem 1.125rem;
  color: #1b223c;
  color: #1b223c;
  font-size: 0.8rem;
  font-weight: 500;
}

.app-brand a img {
  max-width: 230px;
  vertical-align: middle;
}

.app-brand .brand-name {
  color: #1b466c;
  font-size: 1.5rem;
  margin-left: 0rem !important;
  line-height: auto;
  max-width: 170px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
}

.app-brand {
  background-color: white !important;
}

.sidebar .nav > li.active > a i {
  color: #009592;
}

.sidebar {
  /* background-color: #1b223c !important; */
  background-color: #1f3352 !important;
}

.sidebar-footer {
  background-color: #1f3352 !important;
}

/* .sidebar-fixed {
  position: fixed !important;
} */

.sidebar-footer-content p {
  font-size: 0.8rem;
  margin-bottom: 10px;
}

.tab-content,
.tab-pane,
.tab-pane .list-unstyled {
  height: 100% !important;
  display: block;
}

.nav-tabs.nav-style-border .nav-item .nav-link:hover,
.nav-tabs.nav-style-border .nav-item .nav-link.active {
  border-color: transparent;
  background-color: transparent;
  color: #1c446a;
}

.expendable-data-table tr.shown td.details-control::before,
.expendable-data-table td.details-control::before {
  content: "\f04b";
  font: normal normal normal 1em "Material Design Icons";
  font-weight: 300;
  line-height: 1;
  color: #8a909d;

  border-radius: 16px;
  text-align: center;
  vertical-align: center;
  background-color: none;
}

.widget-media .icon {
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  flex: none;
}

.sidebar-toggle {
  background-color: white !important;
  color: grey !important;
}

.sidebar-toggle:hover {
  background-color: white;
  color: lightgrey !important;
}

.dropdown .user-menu {
  text-align: right !important;
}
.page-wrapper {
  padding-left: 0px !important;
}

.header-fixed .page-wrapper {
  padding-top: 4.75rem;
}

/*.radio-custom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 10px;
}*/

.form-control-plain {
  display: block;
  width: 100%;
  padding: 0.59rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plain.form-control-sm,
.form-control-plain.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control[type="file"] {
  height: 100% !important;
}

.result-unknown {
  font-weight: bold;
  cursor: pointer;
}

.result-low {
  color: green;
  font-weight: bold;
  cursor: pointer;
}

.result-medium {
  color: rgb(255, 192, 0);
  font-weight: bold;
  cursor: pointer;
}

.result-high {
  color: red;
  font-weight: bold;
  cursor: pointer;
}

.result-basic {
  font-weight: bold;
  cursor: pointer;
}

.result-seen {
  font-weight: normal !important;
}

#sidebar {
  position: fixed !important;
  padding-top: 4.75rem !important;
  flex-direction: column !important;
  height: 100% !important;
  z-index: 2 !important;
}

.table-without-top {
  border-top: none !important;
}

.table-without-bottom {
  border-bottom: none !important;
}

.table-bordered th,
.table-bordered td,
.table-bordered {
  border-right: none !important;
  border-left: none !important;
}

.table-bordered,
.table-bordered thead tr th {
  border-top: none !important;
  border-top-color: white !important;
}

.order .dropup .caret,
.dropup .caret {
  transform: rotate(180deg) !important;
  color: grey !important;
  margin-left: 0.2rem !important;
}

.order .dropdown .caret {
  color: grey !important;
  margin-left: 0.2rem !important;
}

.react-bootstrap-table-sort-order {
  color: grey !important;
}

.sortable {
  cursor: pointer !important;
}

.filter-label {
  display: block !important;
  margin-top: 0.2rem !important;
  margin-bottom: 0 !important;
}

.date-filter {
  display: flex !important;
}

.date-filter label,
.date-filter .filter-label {
  /* width: 2rem !important; */
  display: block !important;
  margin-top: 0.2rem !important;
  margin-right: 0.2rem !important;
  margin-bottom: 0 !important;
}

.grid-table {
  background-color: rgb(231, 229, 229) !important;
  min-width: 620px !important;
}

.custom-btn {
  margin: 10px;
  margin-bottom: 0px !important;
  margin-left: 0px !important;
}

.page-wrapper-new {
  display: flex !important;
  padding: relative !important;
  margin: auto !important;
  /* overflow-x: auto !important;
  overflow-y: hidden !important; */
}

.content {
  padding-top: 6.75rem !important;
  padding-left: 18rem !important;
  padding-bottom: 5rem !important;
  width: 100% !important;
}

.content-without-sidebar {
  padding-left: 3rem !important;
  padding-bottom: 4rem !important;
}

.content-without-navbar {
  padding: 3rem !important;
}

@media screen and (max-width: 580px) {
  .content {
    padding-left: 6rem !important;
  }
}

/*
-----------------------------
new styles : Ergebnisbericht
-----------------------------
*/

.person_list_small {
  height: 16px;
  width: 16px;
  padding: 2px;
}

.trafficLight_patient {
  width: 100%;
  height: auto;
  max-height: 100px;
}

.trafficLight {
  /*background-color: #444444;
  max-width: 50px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
  border-radius: 3rem;*/

  background-color: #444444;
  max-width: 50px;
  width: 100%;
  height: auto;
  max-height: 100px;
  border-radius: 3rem;
}
svg.trafficLight rect {
  fill: #444444;
}
svg.trafficLight ellipse {
  fill: #8b909c;
}

.redBox svg.trafficLight ellipse:nth-child(2) {
  fill: var(--bs-danger);
}
.greenBox svg.trafficLight ellipse:nth-child(4) {
  fill: var(--bs-success);
}
.yellowBox svg.trafficLight ellipse:nth-child(3) {
  fill: var(--bs-warning);
}
/*
.trafficLight span {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: #646465;
}
.trafficLight .red {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: red;
}
.trafficLight .yellow {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: yellow;
}
.trafficLight .green {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: green;
}
*/
.chartBox {
  width: 800px;
}

.slidecontainer {
  position: relative;
  width: 100%;
}

.slider {
  position: relative;
  -webkit-appearance: none;
  /*width: 100%;*/
  height: 25px;
  background-image: linear-gradient(to right, green, yellow, red);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 50px;

  margin: 0;
  /*width: calc(100% - 130px);
  margin: 0px 65px;*/
}

.slider-range {
  margin: 0 14px; /*0 65px;*/
  height: 25px;
  position: relative;
}

.slider-pointer {
  display: block;
  background-color: #1a223e;
  position: absolute;
  top: calc(50% - 8px);
  left: 100%;
  width: 16px;
  height: 16px;
  margin-left: -8px;
  -webkit-border-radius: 999px;
  border-radius: 999px;
  border: 2px solid #ffffff;
  box-shadow: 0px 2px 3px 2px rgb(26 34 62 / 20%);
}

.slider-pointer-container {
  position: relative;
  width: calc(100% - 130px);
  margin-bottom: 8px;
}

.slider-pointer-val {
  width: 130px;
  max-width: 130px;
  position: relative;
  box-shadow: 0px 2px 3px 2px rgb(26 34 62 / 20%);
}

.slider-pointer-val svg {
  visibility: hidden;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 5px;
  height: 60px;
  background: #f20f0f;
  cursor: default;
  border-radius: 30px;
}

.slider::-moz-range-thumb {
  width: 5px;
  height: 60px;
  background: #f20f0f;
  cursor: default;
  border-radius: 30px;
}

/* Responsive Slider Design */
@media (min-width: 768px) {
  .slider {
    margin: 0 51px;
  }
  .slider-pointer-val svg {
    visibility: visible;
  }
}
@media (max-width: 767px) {
  .slider-pointer-val {
    margin-left: 50% !important;
  }
}

.ampelstatus {
  position: absolute;
  bottom: 0;
  right: 0px;
  width: 12px;
  height: 12px;
  border: 2px solid transparent;
  border-radius: 50%;
  background-color: red;
}

.ampelstatus-container {
  display: flex;
  align-items: center;
  background-color: red;
  height: 100%;
}

.ampelstatus-svg-container {
  /*margin: 0 auto;
  display: block;*/
  vertical-align: middle;
  display: inline-block;
}

/* .eb-navigation {
  height: 50px;
} */

/* Printable DIN-A4 CSS */
@media print {
  .pagebreak {
    page-break-before: always;
  } /* page-break-after works, as well */
  /*h2 {page-break-before:always}*/

  .not_printable {
    display: none;
  }

  #print_dina4 .col-print-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  #print_dina4 #collapse_entlassbericht_clinician .card-footer {
    display: none;
  }

  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    print-color-adjust: exact !important; /*Firefox*/
  }
}

/* Adjustments to Sleek.css */

.accordion .card-body {
  box-shadow: none;
}

/* #### */

.btn-primary {
  background-color: #1e4569;
  border-color: #1e4569;
}

.btn-primary:focus {
  background-color: #1e4569 !important;
  border-color: #1e4569 !important;
}

.btn-primary:hover {
  background-color: #122b3d;
  border-color: #122b3d;
}

.switch-primary > .switch-input:checked ~ .switch-label {
  background-color: #122b3d !important;
  border-color: #122b3d !important;
}

#switch-label-disabled {
  background-color: #b2b2b2 !important;
  border-color: #bcbcbc !important;
}

.text-grey {
  color: grey;
}

.text-print-sub {
  padding-left: 1rem;
}

#final_report_printview {
  padding-left: 1rem;
}

.bullet-point {
  list-style-type: disc !important;
}

.card-custom {
  margin: 1rem !important;
  max-width: 25rem !important;
}

/****FOOTER***/
.footer-custom {
  margin-left: 250px !important;
}

@media screen and (max-width: 580px) {
  .footer-custom {
    margin-left: 85px !important;
  }
}

.footer-link:hover {
  color: rgba(0, 0, 0, 0.7) !important;
  text-decoration: none !important;
}

.footer-link {
  color: #8a909d !important;
  margin-right: 1rem !important;
}

.hideShowPw {
  cursor: pointer;
  height: auto;
  width: 22px !important;
}

.hideShowNav {
  padding-top: 12px !important;
}

.navbar-dropdown .dropdown-menu {
  width: auto;
}

.nav-custom {
  padding-right: 1.9rem !important;
}

#backToLogin {
  margin-bottom: 1rem !important;
}

.notValid {
  color: red !important;
  font-weight: bold !important;
}

.containerLoading {
  display: flex !important;
  align-items: center !important;
}

.loadingDiv {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.loadingSpinner {
  display: flex !important;
  border-width: 0.3rem !important;
  align-self: center !important;
  height: 3rem !important;
  width: 3rem !important;
}

.tablehead-fixed {
  position: sticky;
  top: 75px;
  background-color: white;
  z-index: 1;
}

#body {
  overflow: initial !important;
}
